








































import { Component, Prop, Vue } from 'vue-property-decorator';
import MButton from '@/shared/components/MButton.vue';

@Component({
  components: {
    MButton,
  },
})
export default class AutomationsEditModalKnowHow extends Vue {
  @Prop({ required: true }) modalId: string;

  defaultUrl: string = 'https://www.youtube.com/embed/otcuIchShGQ';
}
